.error-modal.rtl{
  .ant-modal-body{
    .error-modal-title{
      text-align: right;
    }
    .error-modal-message{
      text-align: right;
    }
  }
}
.ant-drawer.rtl {
  .ant-drawer-body {
    .btn {
      flex-direction: row-reverse;

      span {
        margin-right: 4rem;
        margin-left: 0;
      }

      .drawer-icon {
        margin-right: 0;
        margin-left: 1.3rem;
      }
    }
  }
}
.history-row-content-2.rtl {
  direction: rtl;
}
.menu-container.rtl {
  flex-direction: row-reverse;

  .menu-right-mobile{
    flex-direction: row-reverse;
  }
  .menu-right{
    flex-direction: row-reverse;

    .menu-input-icon {
      margin-left: 0rem;
      margin-right: 1rem;
      input, input:focus {
        text-align: right;
      }
    }
  }

  .menu-left {
    flex-direction: row-reverse;

    .menu-links {
      flex-direction: row-reverse;
    }
  }

  .menu-bell {
    margin-left: 0;
    margin-right: 1rem;
  }

  .popup-container {
    left: 10px;
  }

  //.notification-container {
  //  p {
  //    text-align: right;
  //  }
  //
  //  .notification-header {
  //    flex-direction: row-reverse;
  //  }
  //
  //  .notification-item-container {
  //    flex-direction: row-reverse;
  //    //align-items: flex-start;
  //
  //    &.read {
  //      border-left: $lightgray solid .5px;
  //      border-right: $primary-apli solid 10px;
  //    }
  //
  //    .notification-item-img {
  //      margin-right: 0;
  //      margin-left: 1rem;
  //    }
  //
  //    .notification-item-right {
  //      .notification-item-footer {
  //        flex-direction: row-reverse;
  //      }
  //    }
  //  }
  //}

}


.site-content.rtl {
  .listing-container {
    h3 {
      text-align: right;
    }
    .listing-title {
      text-align: right;
    }
  }

  .carousel-container {
    .carousel-header {
      flex-direction: row-reverse;
    }
  }

  .item-details-container {
    .item-header {
      .row {
        flex-direction: row-reverse;
        text-align: right;
      }

      .item-header-left {
        justify-content: flex-end;
      }

      .item-header-right {
        h1 {
          text-align: right;
        }

        .item-header-meta {
          flex-direction: row-reverse;

          p {
            text-align: right;
            border-right: 0;
            border-left: $lightgray solid .5px;

            &:first-child {
              padding-right: 0;
              padding-left: 1rem;
            }

            &:last-child {
              border-left: none;
            }
          }
        }

        .item-header-actions {
          flex-direction: row-reverse;
        }

        .item-header-desc {
          text-align: right;
          direction: rtl;
        }
      }
    }

    .item-footer {
      direction: rtl;
      .row {
        flex-direction: row-reverse;
      }

      h3 {
        text-align: right;
      }
    }

    .action-button {
      flex-direction: row-reverse;

      &:first-child {
        margin-right: 0;
        margin-left: 1rem;
      }
    }

    .episodes-container {
      flex-direction: row-reverse;

      .episodes-seasons {
        button {
          flex-direction: row-reverse;
        }
      }

      .episodes-list {

        button {
          flex-direction: row-reverse;

          .episodes-list-left {
            flex-direction: row-reverse;

            .episodes-list-number {

              margin-left: 2rem;
              margin-right: 0;
            }


          }

        }
      }
    }
  }

  .bundle-row-container {
    flex-direction: row-reverse;

    .bundle-row-content {
      flex-direction: row-reverse;

      .bundle-row-content-desc {
        margin-left: 0;
        margin-right: 1rem;
        text-align: right;
      }
    }
  }

  .panel-container {
    .panel-header {
      flex-direction: row-reverse;
    }
  }

  .settings-container {
    p, h3, textarea {
      text-align: right;
    }

    .settings-dropdown-title {
      margin-right: 1rem;
    }

    .settings-item {
      flex-direction: row-reverse;

      .settings-radio-item {
        flex-direction: row-reverse;

        label {
          margin-left: 0;
          margin-right: 15px;
        }
      }
    }

    .link-device {
      .settings-device {
        flex-direction: row-reverse;

        .settings-device-desc {
          margin-right: 2rem;
          margin-left: 0;
        }
      }
    }

    .deactivate-device {
      .row {
        flex-direction: row-reverse;

        .col-3 {
          text-align: right;
        }
      }

      .deactivate-info {
        flex-direction: row-reverse;

        .deactivate-info-title {
          margin-left: 1.5rem;
          margin-right: 0;
        }
      }

      .deactivate-device-buttons {
        button:first-child {
          margin-right: 1rem;
          margin-left: 0;
        }

        button:nth-child(2) {
          margin-left: 1rem;
          margin-right: 0;
        }
      }

    }

    .notification-container {
      p {
        text-align: right;
      }

      .notification-header {
        flex-direction: row-reverse;
      }

      .notification-item-container {
        flex-direction: row-reverse;
        //align-items: flex-start;

        &.read {
          border-right: green solid 10px;
          border-left: $secondary-apli solid .5px;
        }
        //&.read {
        //  border-left: $lightgray solid .5px;
        //  border-right: $primary-apli solid 10px;
        //}

        .notification-item-img {
          margin-right: 0;
          margin-left: 1rem;
        }

        .notification-item-right {
          .notification-item-footer {
            flex-direction: row-reverse;
          }
        }
      }
    }

  }

  .search-container {
    input {
      //text-align: right;
    }
  }


  .drawer-avatar-container {
    .drawer-info {
      flex-direction: row-reverse;
    }
  }

  .confirm-modal {
    .ant-modal-body {
      p {
        text-align: right;
      }

      .confirm-modal-buttons {
        button:first-child {
          margin-right: 0;
          margin-left: 1rem;
        }
      }
    }
  }
  .sub-modal {
    direction: rtl;

    .ant-modal-body {
      .sub-body {
        .sub-item {
          .sub-row-item {
            .sub-icon {
              margin-left: 1rem;
              margin-right: 0;
            }
          }
        }

        .sub-you-get {
          .sub-row {
            .sub-row-item {
              .sub-icon {
                margin-left: 1rem;
                margin-right: 0;
              }
            }
          }
        }
      }

    }
  }
}

.footer.rtl {
  flex-direction: row-reverse;
}
