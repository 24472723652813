@import "./imports";

html {
  position: relative;
  min-height: 100%;
}

body {
  background-color: $primary-apli;
  font-family: 'Montserrat', sans-serif;
}

:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: .75rem;
}

#root {
  position: relative;
  min-height: 100vh;
}

.router {
  .player-on {
    overflow: hidden;
  }
}

.row {
  margin-left: 0;
  margin-right: 0;
}

p {
  margin-bottom: 0;
}

button {
  &:focus {
    outline: 0;
  }
}

.browser-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;

  .browser-error-buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 40%;
  }
}

.offline {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: all 1s ease;
}

.btn-dark-menu {
  background-color: $primary-apli-transparent;
  border-color: transparent;
  color: $white;

  &:hover {
    //border-color: $white;
    color: $white;
  }

  &:disabled {
    background-color: $primary-apli;

  }

  &:not(:disabled):not(.disabled):active {
    background-color: $primary-apli;

  }

  &.bordered {
    border: $secondary-apli solid 1px;
  }

  &.selected {
    background-color: $secondary-apli;
  }
}

.btn-dark {
  background-color: $secondary-apli;
  border-color: transparent;

  &:hover {
    background-color: $secondary-apli;
    border-color: $white;
  }

  &:disabled {
    background-color: $secondary-apli;

  }

  &:not(:disabled):not(.disabled):active {
    background-color: $secondary-apli;
  }

  &.bordered {
    border: $secondary-apli solid 1px;
  }
  &.bordered-selected{
    background-color: $yellowColor;
  }
  &.selected {
    background-color: $secondary-apli;
  }
}

.swiper-pagination-bullet-active {
  background-color: $white;
}

.clickable-light {
  color: $light-font-color;
  cursor: pointer;
}

.clickable-dark {
  color: $dark-font-color;
  cursor: pointer;
}

.vertical-divider {
  height: 70%;
  border: $secondary-apli solid .5px;
  margin: 0 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.site-content {
  padding-bottom: 60px;
  padding-top: 60px;
  min-height: 100vh;
  position: relative;
  //background-color: $secondary-apli;
  background-color: $primary-apli-transparent;

  .fetch-app-pop{
    background-color: $overlay-color-fetch;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 10;

    img{
      position: absolute;
      top: 30%;
    }
  }
  p, h1, h2, h3, h4, h5, label {
    color: $white;
  }
}

.update-pwa-button {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 20px;
  z-index: 10;
}

.iOS-modal {
  z-index: 1000;
  .ant-modal-content {
    background-color: $primary-apli;
  }
  .ant-modal-body{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $primary-apli;

    p{
      color: $white;
      font-size: 13px;
      font-family: sans-serif;
    }

    .form-label-group-checkbox{
      margin-bottom: .5rem;
      .checkbox-referral{
        zoom: 1.5;
        margin-right: 5px;
      }
      .referral-box{
        display:flex;
        color:$white;
        font-size: 12px;
        padding: 0.5rem;
        paddingBottom: 0;
        align-items: center !important;
      }
    }

    .p-img{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 10%;
        padding: 0px 3px;
      }
      &.rtl{
        flex-direction: row-reverse;
      }
    }
    .removePopUp{
      top: 0;
      right: 0;
      position: absolute;
      color: $white;
      cursor: pointer;
    }
    .refresh-image{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      img{
        width: 75%;
        height: 75%;
      }
    }

    .refresh-title, .refresh-title-main{
      margin: 12px 0px;
    }

    .refresh-title-main{
      font-family: "Arial Rounded MT Bold";
      font-size: 18px;
      &.rtl{
        direction: rtl;
      }
    }
    .refresh-msg {
      font-size: 11px;
      color: $white;
    }
    .iOS-modal-buttons{
      display: flex;
      button{
        background-color: $secondary-apli;
        //width: 100%;
        margin-left: 10px;
        color: $white;
        height: 40px;
        font-size: 12px;
        border-color: transparent;
        border-radius: 5px;
      }
    }
    .download-link-view{
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
}

.update-modal{
  .ant-modal-content{
    background-color: transparent;
  }
  .ant-modal-body{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 20px;
    background-image: linear-gradient(20deg, $yellowColor 10%, $secondary-apli 51%, $yellowColor 90%);

    .refresh-image{
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.75);
      border-radius: 50%;
      width: 100px;
      height: 100px;
      .img{
        width: 75%;
        height: 75%;
      }
    }
    .refresh-title{
      margin: 30px 0px;
      font-size: 18px;
      color: $white;
      font-family: "Arial Rounded MT Bold";
    }
    .refresh-msg{
      font-size: 11px;
      color: $white;
    }
    .update-button{
      border: none;
      background-color: $white;
      //1fc8db
      color: $primary-apli;
      font-family: "Arial Rounded MT Bold";
      border-radius: 15px;
      margin-top: 20px;
      width: 100px;
      height: 30px;
    }

  }
}

.ant-select-dropdown.ant-select-dropdown--single.ant-select-dropdown-placement-bottomLeft{
  background-color: $secondary-apli;

  .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled{
    color: darkgray;
    font-size:12px;
  }
  .ant-select-dropdown-menu-item{
    color: $white;
    font-size:15px;
    font-family: "Arial Rounded MT Bold";
  }
  .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active,.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected{
    background-color: $yellowColor;
  }
  .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected.ant-select-dropdown-menu-item-disabled{
    color: darkgray;
    background-color: $secondary-apli;
    font-size:12px;
  }
}

.ant-drawer-wrapper-body {

  background-color: $primary-apli;

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 0;

    .btn {
      border-radius: 0;
      height: 60px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;


      span {
        margin-left: 4rem;
      }

      .drawer-icon {
        font-size: 20px;
        margin-right: 1.3rem;
        width: 24px;
      }

      &:focus {
        outline: 0;
        box-shadow: unset;
      }
    }
  }
}

.ant-message .anticon {
  top: -3px;
}

.ant-popover-placement-bottom .ant-popover-content .ant-popover-inner{
  .ant-popover-inner-content{
    background-color: $primary-apli-transparent;
    .ant-popover-message{
      color: $white;
      .anticon{
        color: $white;
      }
    }
    .ant-popover-buttons {
      button{
        background-color: $secondary-apli;
        border: none;
        color: $white;
      }
    }
  }
}

.home-slider {
  position: relative;

  .home-slider-arrow {
    position: absolute;
    width: 100px;
    height: 100%;
    display: flex;
    //justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    z-index: 2;

    &.home-slider-left-arrow {
      left: 0;
    }

    &.home-slider-right-arrow {
      right: 0;
    }

    .home-slider-arrows-icon {
      color: $white;
      font-size: 40px;
    }

  }

  .slick-dots {
    bottom: 0;
    height: 60px;
    display: flex !important;
    justify-content: center;
    align-items: center;

    li button:before {
      color: $white;
    }
  }
}

.menu-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  height: 60px;
  background-color: $primary-apli-transparent;
  padding: 0 2rem;
  z-index: 5;
  border-bottom: $secondary-apli solid 1.5px;

  .menu-right-mobile {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }

  .menu-right {
    display: flex;
    flex: 2;
    align-items: center;
    justify-content: flex-end;
    height: 100%;

    .menu-input-icon {
      display: flex;
      align-items: center;
      width: 200px;
      height: 40px;
      position: relative;
      border: $yellowColor solid 1px;
      border-radius: 5px;
      padding-left: 10px;
      margin-left: 1rem;
      background-color: $secondary-apli;

      input, input:focus {
        background-color: $secondary-apli;
        color: $white;
        border: none;
        outline: none;
        width: 145px;
      }

      .menu-search-icon {
        //position: absolute;
        font-size: 30px;
        color: $lightgray;
        //right: 0;
        margin: auto 10px;
        height: 100%;
        cursor: pointer;

        &:hover {
          color: $white;
        }
      }
    }
  }

  .menu-center {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;

    .title{
      color:red;
      font-family: "Arial Rounded MT Bold";
      padding-left: 2px;
      font-size: 22px;
    }
    .menu-logo {
      height: 50px;
      width: 100px;
    }
  }

  .menu-left {
    display: flex;
    flex: 2;
    align-items: center;
    height: 100%;

    .menu-links {
      display: flex;
      align-items: center;
      height: 100%;

      .selected {
        border-bottom: $yellowColor solid 2px;
        font-size: 18px;
        font-family: "Arial Rounded MT Bold";
      }
    }
  }

  .menu-hamburger {
    font-size: 1.5em;
    color: $white;
  }

  .menu-bell {
    cursor: pointer;
    margin-left: 1rem;

    .ant-badge {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.notification-menu-selected {
      .menu-bell-icon {
        display: none;

        &.selected {
          display: flex;
        }
      }
    }

    .menu-bell-icon {
      display: flex;

      &.selected {
        display: none;
      }

      color: $white;
      font-size: 25px;
      width: 25px;
    }
  }

  .menu-link {
    margin: 0 10px;
    font-size: 15px;
    color: $white;

    &:hover {
      text-decoration: none;
    }
  }

  .popup-container {
    position: fixed;
    width: 500px;
    min-height: 600px;
    height: 80vh;
    background-color: $primary-apli-transparent;
    top: 55px;
    right: 10px;
    border-radius: 10px;
    border: solid $secondary-apli 1px;
    -webkit-box-shadow: $box-shadow-light;
    box-shadow: $box-shadow-light;
    overflow-y: auto;
    p, h4 {
      color: $white;
    }
  }
}

.hero-img {
  width: 100%;
  cursor: pointer;
  height: 65vh;
}

.live-stream-card{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: .5rem;

  p{
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
  }

  img{
    width: 80px;
    height: 80px;
    border-radius: 40px;
    cursor: pointer;

    &.live{
      border: 3px solid red;
      animation: 2s infinite live;
    }
  }
}

.live-card-container{
  width: 100px;
  position: relative;
  height: 100px;
  border: red 3px solid;
  cursor: pointer;
  border-radius:50%;

  .live-card-img{
    width: 94px;
    height: 94px;
    position: absolute;
    border-radius:50%;
  }
}
.movie-card-container {
  width: 160px;
  margin: 1rem .4rem;
  position: relative;
  transform: scale(1);
  transition: all .3s ease;

  .movie-card-continue-img{
    position: absolute;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 10;
    cursor: pointer;
    top: 33%;
    right: 33%;
    color: $white;
    .action-button-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
    }
  }

  &:hover{
    transform: scale(1.1);
    transition: all .5s ease;
  }

  &:active {
    transform: scale(.95);
    transition: all .3s ease;
  }

  a {
    color: $black;

    &:hover {
      text-decoration: none;
    }
  }

  .movie-card-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 40px;
  }
  .movie-card-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 225px;
    background-color: $overlay-color;
    color: $white;
    z-index: 2;
    display: none;
    cursor: pointer;
    text-align: center;
    overflow: hidden;

  }

  &:hover .movie-card-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .movie-card-img {
    border-radius: 10px;
    -webkit-box-shadow: $box-shadow;
    box-shadow: $box-shadow;
    width: 160px;
    height: 235px;
  }

  .movie-card-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    //height: 28px;
    padding: 0 4px;

    .movie-card-title {
      width: 100%;
      text-align: center;
      margin-bottom: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &.rtl{
        direction: ltr;
      }
      &:hover{
        white-space: normal;
      }
    }

    .movie-card-star {
      color: $white;
      font-size: 16px;
    }
  }
}

.bundle-card-container {
  width: 160px;
  margin: 1.5rem .4rem;
  position: relative;
  transform: scale(1);
  transition: all .3s ease;

  .bundle-card-continue-img{
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 10;
    cursor: pointer;
    top: 32%;
    right: 32%;
    color: $white;
  }

  &:hover{
    transform: scale(1.1);
    transition: all .5s ease;
  }

  &:active {
    transform: scale(.95);
    transition: all .3s ease;
  }

  a {
    color: $black;

    &:hover {
      text-decoration: none;
    }
  }

  .bundle-card-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 40px;
  }

  .bundle-card-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 235px;
    background-color: $overlay-color;
    color: $white;
    z-index: 2;
    display: none;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
  }

  &:hover .bundle-card-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;

    .bundle-card-title {
      width: 100%;
      text-align: center;
      margin-bottom: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-top: 10px;

      &.rtl{
        direction: ltr;
      }
      &:hover{
        white-space: normal;
      }
    }

    .bundle-card-buttons{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 30px;
      border: none;
      background-color: transparent;
    }
  }

  .bundle-card-img {
    width: 160px;
    height: 235px;
    border-radius: 10px;
    -webkit-box-shadow: $box-shadow;
    box-shadow: $box-shadow;
  }
  .myProgress{
    width: 100%;
    background-color: lightgray;
    .myBar{
      background-color: red;
      margin-top: 1px;
    }
  }

  .bundle-card-star {
    color: $yellowColor;
    fill: $yellowColor;
    font-size: 16px;
    position: absolute;
    bottom: 6px;
    left: 10px;
  }

  .bundle-card-footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    //height: 28px;
    padding:4px;

    .bundle-card-title {
      width: 100%;
      text-align: center;
      margin-bottom: 0;
      white-space: nowrap;
      font-size: 15px;
      font-family: sans-serif;
      text-overflow: ellipsis;
      overflow: hidden;

      &.rtl{
        direction: ltr;
      }
      &:hover{
        white-space: normal;
      }
    }

    .bundle-card-star {
      color: $yellowColor;
      fill: $yellowColor;
      font-size: 16px;
    }
  }
}

.channel-card-container {
  width: 200px;
  margin: 1rem .4rem;
  position: relative;
  transform: scale(1);
  transition: all .3s ease;

  &:hover{
    transform: scale(1.1);
    transition: all .5s ease;
  }

  &:active {
    transform: scale(.95);
    transition: all .3s ease;
  }


  a {
    color: $black;

    &:hover {
      text-decoration: none;
    }
  }

  .channel-card-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 40px;
  }

  .channel-card-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    background-color: $overlay-color;
    color: $white;
    z-index: 2;
    display: none;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
  }

  &:hover .channel-card-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .channel-card-img {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    -webkit-box-shadow: $box-shadow;
    box-shadow: $box-shadow;
  }

  .channel-card-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    //height: 28px;
    padding: 0 4px;

    .channel-card-title {
      width: 100%;
      text-align: center;
      margin-bottom: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 15px;
      font-family: sans-serif;

      &.rtl{
        direction: ltr;
      }
      &:hover{
        white-space: normal;
      }
    }

    .channel-card-star {
      font-size: 16px;
      color: $white;
    }
  }
}

.series-card-container {
  width: 175px;
  margin: 1rem .4rem;
  position: relative;
  transform: scale(1);
  transition: all .3s ease;

  .series-card-continue-img{
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 10;
    cursor: pointer;
    top: 32%;
    right: 32%;
    color: $white;
  }

  &:hover{
    transform: scale(1.1);
    transition: all .5s ease;
  }

  &:active {
    transform: scale(.95);
    transition: all .3s ease;
  }


  a {
    color: $black;

    &:hover {
      text-decoration: none;
    }
  }

  .series-card-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 40px;
  }

  .series-card-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 180px;
    background-color: $overlay-color;
    color: $white;
    z-index: 2;
    display: none;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
  }

  &:hover .series-card-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .series-card-img {
    width: 175px;
    height: 175px;
    border-radius: 10px;
    -webkit-box-shadow: $box-shadow;
    box-shadow: $box-shadow;
  }

  .series-card-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    //height: 28px;
    padding: 0 4px;

    .series-card-title {
      width: 100%;
      text-align: center;
      margin-bottom: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 15px;
      font-family: sans-serif;

      &.rtl{
        direction: ltr;
      }
      &:hover{
        white-space: normal;
      }
    }

    .series-card-star {
      color: $white;
      font-size: 16px;
    }
  }
}

.item-card-container {
  width: 160px;
  margin: 1rem .4rem;
  position: relative;
  transform: scale(1);
  transition: all .3s ease;

  &:active {
    transform: scale(.95);
    transition: all .3s ease;
  }


  a {
    color: $black;

    &:hover {
      text-decoration: none;
    }
  }

  .item-card-x {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 30px;
    height: 30px;
    font-size: 18px;
    color: $red;
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 3;
  }

  .item-card-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 235px;
    background-color: $overlay-color;
    color: $white;
    z-index: 2;
    display: none;
    cursor: pointer;
    text-align: center;
    overflow: hidden;

  }


  &:hover .item-card-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .item-card-img {
    width: 160px;
    height: 235px;
    border-radius: 10px;
    -webkit-box-shadow: $box-shadow;
    box-shadow: $box-shadow;
  }

  .item-card-star {
    color: $yellowColor;
    fill: $yellowColor;
    font-size: 16px;
    position: absolute;
    bottom: 5px;
    left: 10px;
  }

  .item-card-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    //height: 28px;
    padding: 0 4px;

    .item-card-title {
      width: 100%;
      text-align: center;
      margin-bottom: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .item-card-star {
      color: $white;
      font-size: 16px;
    }
  }
}

.history-card-container {
  width: 200px;
  margin: 1rem .4rem;
  position: relative;
  border: $lightgray solid .5px;
  border-radius: 10px;
  overflow: hidden;
  transform: scale(1);
  transition: all .3s ease;

  &:active {
    transform: scale(.95);
    transition: all .3s ease;
  }


  a:hover {
    text-decoration: none;
  }

  .history-card-img {
    width: 200px;
    height: 200px;
  }

  .history-card-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px;

    .history-card-title {
      width: 100%;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .history-card-subtitle {
      width: 100%;
      text-align: center;
      color: $white;
      font-size: 12px;
    }
  }
}

.carousel-container {
  margin: 1rem;


  .carousel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin: 0 1rem;

    a:hover {
      text-decoration: none;

    }

    .carousel-header-right {
      display: flex;
      align-items: center;

      .carousel-header-right-icon {
        color: $white;
        font-size: 20px;
        margin: 0 1rem;
      }
    }
  }
}
.player-wrapper {
  position: relative;
  padding-top:45%;
  border-bottom: 4px solid $secondary-apli;
  display: flex;
  justify-content: center;

  .watch-trailer-container {
    height: 90px;
    width: 140px;
    //margin: 1rem .4rem;
    bottom: -45px;
    position: relative;
    transform: scale(1);
    //left: 44%;
    transition: all .3s ease;
    border: 2px solid $white;
    border-radius: 4px;
    background-color: black;

    &:hover{
      transform: scale(1.2);
      transition: all .5s ease;
    }

    &:active {
      transform: scale(.95);
      transition: all .3s ease;
    }
    .player-card-badge {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .watch-trailer-overlay {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $overlay-color;
      color: $white;
      z-index: 2;
      display: none;
      cursor: pointer;
      text-align: center;
      overflow: hidden;

      .watch-trailer-title{
        font-size: 14px;
        margin: 0;

      }
    }
    &:hover .watch-trailer-overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      //border-radius: 10px;
    }
  }

  .p-player-wrapper{
    position: absolute;
    top: 7%;
    left: 7%;
  }
  .mute-player-action{
    position: absolute;
    bottom: 20px;
    left: 90%;
    padding-left: 5px;
    border-radius: 1rem;
    width: 25px;
    background-color: $white;
  }
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.login-container {
  background-position: 75% 50%;
  //background: linear-gradient(to right, #0062E6, #33AEFF);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-size: cover;

  .fetch-app-pop{
    background-color: $overlay-color-fetch;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 10;
  }

  .login-container-left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    img {
      width: 300px;
      padding-right: 10px;
    }

    h2 {
      text-align: center;
      color: $white;
      font-weight: bold;
    }
  }

  .header-enrch-number{
    margin-bottom: 15px;
    display:flex;
    justify-content: center;
    background:transparent;
    align-items: center;

    p{
      margin-left: 1rem;
      color: white;
      font-size: 16px;
      font-family: 'Montserrat', sans-serif;
    }
    img{
      width: 50px;
      height: 35px;
    }
  }


  .freeMsg-text{
    color:white;
    margin: 1rem;
    font-size: 9px;
  }

  .selectWrapper{
    margin: 0rem 2rem;
    border-radius:36px;
    margin-bottom: 15px;
    //width: 100%;
    display:flex;
    justify-content: space-between;
    overflow:hidden;
    background:transparent;
    border:2px solid $secondary-apli;

    .ant-select-disabled .ant-select-selection__rendered,.ant-select-selection{
      background-color: $primary-apli;
      border-color: $primary-apli;

      &:hover{
        border-color: $primary-apli;
      }
      //.ant-select-disabled .ant-select-selection-selected-value{
      //  svg {
      //    color: grey;
      //  }
      //}
    }

    .ant-select-disabled .ant-select-selection-selected-value{
      color: grey;
    }
    .ant-select-disabled .ant-select-selection .ant-select-arrow{
      svg{
        color: grey;
      }
    }

    .settings-dropdown.ant-select.ant-select-enabled .ant-select-selection{
      background-color: $primary-apli;
      border: transparent;
      color: $white;
      font-size: 16px;

      .ant-select-arrow{
        color: $white;
        font-size: 12px;
      }
      .option-select{
        background-color: $yellowColor;
      }
    }
    .require-image{
      width: 54px;
      height: 38px;
      padding-left: 18px;
      padding-top: 10px;
    }
  }
  .selectBox{
    width:100%;
    height:48px;
    background-color: transparent;
    color: $white;
    border:0px;
    outline:none;
    cursor: pointer;
    margin-left: 12px;
    margin-right: 12px;
  }

  .card-signin {
    //max-width: 90%;
    //margin: auto;
    background-color: $primary-apli;
    border: 0;
    border-radius: 1rem;
    margin-left: 12px;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  }
  .card-signin .center{
    color: $white;
    text-align: center;
  }
  .card-signin .card-title {
    margin-bottom: 2rem;
    font-weight: 300;
    font-size: 1.5rem;
  }

  .card-signin .card-body {
    padding: 0rem 2rem 2rem;
  }

  .form-signin {
    width: 100%;
    .freeBundle-text{
      color: $white;
      font-size: 11px;
      padding-top: 12px;
    }
  }

  .form-signin .btn {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    transition: all 0.2s;
  }

  .form-label-group {
    position: relative;
    margin-bottom: .5rem;
  }
  .form-label-group-checkbox{
    margin-bottom: .5rem;
    .checkbox-referral{
      zoom: 1.5;
      margin-right: 5px;
    }
    .referral-box{
      display:flex;
      color:$white;
      padding: 0.5rem;
      paddingBottom: 0;
      alignItems:center;
    }
  }
  .form-control{
    background-color: transparent;
    border-width: 2px;
  }
  .form-control:focus{
    box-shadow:0 0 0 0rem rgba(0, 0, 0.16, 0.8);
  }

  .form-label-group input {
    height: auto;
    border-radius: 2rem;
    padding-left: 3rem;
    border-color: $secondary-apli;
  }

  .form-label-group > input,
  .form-label-group > label {
    padding: var(--input-padding-y) var(--input-padding-x);
  }

  .form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    /* Override default `<label>` margin */
    line-height: 1.5;
    color: gray;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
  }

  .form-label-group input::-webkit-input-placeholder {
    color: transparent;
  }

  .form-label-group input:-ms-input-placeholder {
    color: transparent;
  }

  .form-label-group input::-ms-input-placeholder {
    color: transparent;
  }
  .form-label-group input::-ms-input-placeholder {
    color: transparent;
  }

  .form-label-group input::-moz-placeholder {
    color: transparent;
  }

  .form-label-group input::placeholder {
    color: transparent;
  }

  .form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3);
    color: $white;
  }

  .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 10px;
    color: $white;
  }

  .form-label-group input:not(:placeholder-shown) ~ .uplabel {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 10px;
    color: red;
  }

  .btn-google {
    color: $white;
    background-color: #ea4335;
  }

  .btn-facebook {
    color: $white;
    background-color: #3b5998;
  }

  /* Fallback for Edge
  -------------------------------------------------- */

  @supports (-ms-ime-align: auto) {
    .form-label-group > label {
      display: none;
    }
    .form-label-group input::-ms-input-placeholder {
      color: #777;
    }
  }

  /* Fallback for IE
  -------------------------------------------------- */

  @media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
    .form-label-group > label {
      display: none;
    }
    .form-label-group input:-ms-input-placeholder {
      color: #777;
    }
  }

  .login-number-input {
    padding-left: 4rem !important;
  }

  .login-number-label {
    left: 40px !important;
  }

  .proxy-label {
    width: 15px;
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;

    img {
      margin-left: 15px;
    }

    &:after {
      content: "▼";
      position: absolute;
      font-size: 12px;
    }
  }

  .proxy-select {
    width: 50px;
    opacity: 0;
    z-index: 1;
    position: absolute;
  }


}

.listing-container {
  top: 60px;
  position: relative;
  //padding: 2rem;

  h3 {
    margin-top: 1rem;
  }

  .listing-title {
    margin: 1rem;
  }

  .row {
    justify-content: center;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
  }
}

.item-details-container {
  max-width: 1200px;
  margin: auto;
  position: relative;

  .background{
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //filter: blur(5px);
    opacity:0.2;
  }
  .item-header {
    padding: 2rem 2rem;

    .item-header-left {
      display: flex;

      .item-poster {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;

        &.item-poster-movie {
          max-width: 250px;
          //max-height: 375px;
        }

        &.item-poster-series {
          max-width: 300px;
          //max-height: 240px;
        }

        &.item-poster-bundle {
          max-width: 250px;
          //max-height: 250px;
        }

        img {
          //width: 100%;
          border-radius: 10px;
          overflow: hidden;
          -webkit-box-shadow: $box-shadow-light;
          box-shadow: $box-shadow-light;
          width: 170px;
          height: 240px;
        }

        .item-poster-overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          background-color: $overlay-color;
          color: $white;
          display: flex;
          justify-content: center;
          align-items: center;

          .item-poster-overlay-icon {
            font-size: 60px;
            color: $white;
          }
        }
      }
    }

    .item-header-right {
      .item-header-meta {
        display: flex;

        p {
          padding: 0 1rem;
          border-right: $lightgray solid .5px;
          line-height: 80%;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            border-right: none;
          }

          img {
            margin-top: -2px;
          }
        }
      }

      img{
        display: none;
      }

      .item-header-actions {
        display: flex;
        margin-top: 1rem;
        align-items: center;

        .seasons-dropdown {
          width: 60%;
        }
      }

      .item-header-desc {
        display: flex;
        margin-top: 1rem;
        p{
          font-size: 16px;
        }
        .seeMore{
            white-space: pre-wrap;
            -webkit-line-clamp: 3;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
        .seeLess{
          white-space: pre-wrap;
        }
      }

    }

  }

  .item-footer {
    max-width: 1200px;
    padding: 1rem;
    margin: auto;

    h3 {
      padding: 15px;
    }
  }

  .action-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 20px;
    margin: 0 1rem;

    &:hover, &:active, &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }

    &:first-child {
      margin-left: 0;
    }

    .action-button-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
    }
  }

  .action-icon {
    color: $white;
    font-size: 30px;
    margin: 0 1.5rem;
  }

  .episodes-container {
    padding: 2rem;
    max-width: 1200px;
    margin: auto;

    .episodes-seasons {
      display: flex;
      flex-direction: column;
      padding: 0;
      border-radius: 10px;
      overflow: hidden;
      -webkit-box-shadow: $box-shadow;
      box-shadow: $box-shadow;


      button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $secondary-apli;
        color: $white;
        height: 60px;
        border: 0;
        width: 100%;
        padding: 0 1rem;

        &:focus {
          outline: 0
        }

        &:hover {
          background-color: $primary-apli
        }

        .season-title {

        }

        .season-episodes-number {
          font-style: italic;
          font-size: 12px;
        }

      }
    }

    .episodes-list {
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      padding: 0;
      overflow: hidden;
      -webkit-box-shadow: $box-shadow;
      box-shadow: $box-shadow;

      button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $secondary-apli;
        color: $white;
        height: 60px;
        border: 0;
        width: 100%;
        padding: 0 1rem;

        &:focus {
          outline: 0
        }

        &:hover {
          background-color: $primary-apli
        }

        .episodes-list-left {
          display: flex;
          align-items: center;

          .episodes-list-number {
            font-size: 30px;
            margin-right: 2rem;
          }

          .episodes-list-title-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .episodes-list-title {
              font-size: 20px;
            }

            .episodes-list-duration {
              font-size: 10px;
              font-style: italic;
              color: $lightgray;
            }
          }

          .episodes-list-right {
            .episodes-list-play-icon {
              font-size: 40px;
            }
          }
        }

      }
    }
  }
}

.player-container-standalone {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: auto;

  .player-back {
    font-size: 40px;
    color: $white;
    position: absolute;
    top: 1rem;
    left: 2rem;
    z-index: 2;
  }

  .video-player {
    background-color: $primary-apli;
    max-height: $inner-screen-size;
  }
}

.player-container {
  position: fixed;
  left: 0;
  top: 60px;
  height: $inner-screen-size;
  width: 100vw;
  max-width: 100%;
  max-height: calc(100% - 60px);
  z-index: 4;
  background-color: $primary-apli;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;


  &.chat-enabled {
    padding: 1rem;

    .player {
      //margin: 1rem;
      //padding: 1rem;
      flex: 7;
    }

    .chat {
      margin: 1rem;
      padding: 1rem;
      flex: 3;
      box-shadow: 0px 0px 8px $secondary-apli;
    }
  }

  .player {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 2;

    &.ios{
      display: flex;
      justify-content: center;
    }

    &.fullscreen {
      .video-player {
        background-color: unset;
        max-height: unset;

        video {
          max-height: 100%;
        }
      }
    }

    .video-player {
      background-color: $primary-apli;
      max-height: $inner-screen-size;

      video {
        max-height: $inner-screen-size;
      }
    }


    .player-controls {
      display: none;
    }

    &.paused {
      .player-controls {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //border: red solid 2px;
        //overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 5;

        &.ios {
          height: 30%;
          top: 15%;
          justify-content: center;
        }

        .player-top-bar {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 70px;
          background: $fade-down;
          padding: 20px;

          &.is-livestream{
            align-items: flex-start;
          }

          .live-stream-barList{
            flex-direction: column;
            align-items: flex-end;

            .player-action-button{
              margin: .35rem 0rem;
              flex-direction: column;

              p{
                border: none;
                background-color: transparent;
                font-size: 13px;
                padding: unset;
                font-family: 'Montserrat', sans-serif;
              }
              img{
                width: 24px;
                height: 24px;
              }
            }

            .live-user-info{
              align-items: center;

              img{
                height: 40px;
                cursor: pointer;
              }
              p{
                border: none;
                background-color: transparent;
                font-size: 12px;
                font-family: 'Montserrat', sans-serif;
                justify-content: center;
              }
            }
          }

          img{
            width: 95px;
            height: 32px;
          }
          p{
            font-size: 14px;
            letter-spacing: 1px;
            font-family: "Arial Rounded MT Bold";
            border-left: 4px solid $yellowColor;
            background-color: $overlay-color-light;
            padding: .4rem;
          }
          div {
            display: flex;
          }

        }

        .player-center {
          display: flex;
          justify-content: space-around;
          align-items: center;
          height: 100%;
          position: relative;
          padding: 0 2rem;

          .live-stream-icon{
            position: absolute;
            top: 0;
            width: 100px;
          }

          &.is-livestream{
            padding: unset;
            margin: 0 4rem;
          }

          .player-action-button {
            font-size: 40px;
          }


          .skip-button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100px;
            width: 100px;
            border-radius: 50%;
            background-color: $overlay-color;
            color: $white;

            &.ios {
              height: 35px;
              width: 35px;
            }
          }
        }

        .player-action-bar {
          display: flex;
          flex-direction: column;
          background: $fade-up;
          padding: 0 20px;

          .player-progress-bar {
            display: flex;
            height: 10px;

          }

          .player-buttons-bar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
          }

          div {
            display: flex;
          }


        }

        .player-action-button {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          width: 40px;
          cursor: pointer;

          &.volume{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            width: 110px;
            cursor: pointer;
          }
          .player-action-button-volume{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            width: 40px;
            cursor: pointer;
          }
          .slider-volume{
            display: flex;
            justify-content: center;
            align-items: center;
            .slider{
              width: 60px;
              background-color: transparent;
              -webkit-appearance: none;
            }
            input[type=range]:focus {
              outline: none;
            }
            input[type=range]::-webkit-slider-runnable-track {
              background: $white;
              border: 0.2px solid #010101;
              border-radius: 1.3px;
              width: 100%;
              height: 6px;
              cursor: pointer;
            }
            input[type=range]::-webkit-slider-thumb {
              margin-top: -8px;
              width: 20px;
              height: 20px;
              background: $white;
              border-radius: 10px;
              cursor: pointer;
              -webkit-appearance: none;
            }
            input[type=range]:focus::-webkit-slider-runnable-track {
              background: $white;
            }
          }
        }
        .browser-error-slider {
          width: 40px;
          margin-right: 20px;
          height: 3px;
          background-color: $white;
          position: relative;

          .rangeslider__handle {
            width: 10px;
            height: 10px;
            top: -3px;
            border-radius: 50%;
            background-color: $white;
            box-shadow: 0 1px 1px transparent;
            position: absolute;
          }
        }

        .player-live-text {
          display: flex;
          align-items: center;
          font-size: 16px;
          text-transform: uppercase;
          padding-left: 10px;

          span {
            font-size: 8px;
            color: red;
            margin: 0 4px;
          }
        }

        .player-popup {
          position: absolute;
          width: 250px;
          //height: 150px;
          background-color: $primary-apli-transparent;
          bottom: 50px;
          right: 20px;
          //border-radius: 10px;
          -webkit-box-shadow: $box-shadow-light;
          box-shadow: $box-shadow-light;
          overflow-y: auto;
          max-height: calc(100% - 50px);
          z-index: 5;
        }

        .player-playlist {
          position: absolute;
          width: 275px;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 6;
          padding: 5px;

          .player-playlist-container {
            //border-radius: 10px;
            background-color: $secondary-apli;
            -webkit-box-shadow: $box-shadow-light;
            box-shadow: $box-shadow-light;
            overflow-y: auto;
            height: 100%;
            //width: 100%;

            .player-playlist-loading-overlay {
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              width: calc(100% - 10px);
              height: calc(100% - 10px);
              background-color: $overlay-color;
            }

            .item {
              border-radius: 0;
              height: 64px;
              width: 100%;
              text-align: left;
              display: flex;
              align-items: center;
              //justify-content: space-between;
              //padding: .5rem 1rem;

              img{
                height: 60px;
                margin-right: 1rem;
                width: 45px;
                border-radius: .3rem;
              }

              p{
                font-size: 16px;
                font-family: 'Montserrat';
              }
            }


          }
        }
      }
    }
    .live-user-info-ios{
      display: flex;
      align-items: center;
      z-index: 1;
      position: absolute;
      justify-content: center;

      img{
        width: 90px;
        height: 40px;
      }
      p{
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
      }
    }
  }

  .chat {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    -webkit-box-shadow: $box-shadow-light;
    box-shadow: $box-shadow-light;
    //min-height: 300px;

    .chat-overlay {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      border-radius: 10px;
      -webkit-box-shadow: $box-shadow-light;
      box-shadow: $box-shadow-light;
      z-index: 2;
      background-color: $overlay-color;

    }

    .chat-body {
      display: flex;
      flex-direction: column;
      //justify-content: flex-end;
      height: 100%;
      //width: 100%;
      //border: red solid 1px;
      margin-bottom: 1rem;
      overflow-y: auto;

      > div {
        margin-top: auto; /* pin items to bottom */

      }

      .chat-item {
        display: flex;
        align-items: center;
        margin: 1rem 0;
        //width: 300px;

        .message-text{
          word-break: break-all;
          padding: 0.25rem;
          border-radius: 0.25rem;
          background-color: $chat-rec-color;
          cursor: pointer;
        }
        .reply-text{
          background-color: gray;
          color: lightgrey;
          padding: 0rem 0.25rem;
          word-break: break-word;
        }
        img {
          cursor: pointer;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 10px;
        }

        &.mine {
          flex-direction: row-reverse;

          .message-text{
            background-color: $chat-mine-color;
          }

          img {
            border: 3px solid $yellowColor !important;
            margin-right: 0;
            margin-left: 10px;
          }
        }
      }

    }
    .reply-text-view{
      display: flex;
      justify-content: space-between;
      min-height: 46px;
      background-color: lightgrey;
      border-radius: 5px;
      margin-bottom: 0.1rem;

      span{
        height: 60%;
        cursor: pointer;
        padding-right: 0.5rem;
      }

      p{
        padding-left: .5rem;
        color: #0b2e13;
        font-size: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
    }

    .chat-input {
      display: flex;
      align-items: center;
      //width: 200px;
      min-height: 34px;
      position: relative;
      background-color: $white;
      border: $lightgray solid .5px;
      border-radius: 5px;
      padding-left: 10px;
      color: $black;

      input, input:focus {
        border: none;
        outline: none;
        width: 100%;
      }

      .menu-search-icon {
        font-size: 20px;
        color: $secondary-apli;
        margin: auto 10px;
        height: 100%;
        cursor: pointer;

        &:hover {
          color: $secondary-apli;
        }
      }
    }

    .player-prediction-question{
      display: flex;
      position: relative;
      height: 50px;
      background-color: $yellowColor;
      border-radius: 10px;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      overflow: hidden;

      .player-prediction-question-text{
        font-size: 17px;
        font-family: "Montserrat";
        position: absolute;
        white-space: nowrap;
        animation: fillPredictionAnimation 8s infinite;
      }
    }

    .player-prediction-answers{
      display: flex;
      flex-direction: column;
      background-color: $primary-apli;
      margin-bottom: .5rem;
      position: relative;

      .loader{
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $overlay-color-light;
      }

      .question-answers{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: .5rem;

        .question-answers-button{
          font-size: 13px;
          text-align: center;
          font-family: "Arial Rounded MT Bold";
          width: 48%;
          color: white;
          height: 45px;
          font-size: 16px;
          border-color: transparent;
          border-radius: 12px;

          background-color: $secondary-apli;
          background-repeat-x: no-repeat;
          background-clip: padding-box;
        }
      }

      .question-title{
        font-size: 15px;
        text-align: center;
        font-family: "Arial Rounded MT Bold";
      }
      .question-text{
        font-size: 13px;
      }
    }
  }

  &.minimized {
    bottom: 10px;
    right: 10px;
    left: unset;
    top: unset;
    width: 380px;
    //height: 300px;
    height: unset;
    transition: 1s ease-out;
    border-radius: 10px;
    overflow: hidden;
    -webkit-box-shadow: $box-shadow;
    box-shadow: $box-shadow;
    padding: 0;

    .player {
      margin: 0;
      padding: 0;

      .player-controls {
        .player-action-bar, .player-action-button, .player-live-text, .player-playlist {
          display: none;
        }

        .player-center {
          display: none;

          &.preview {
            display: flex;
          }
        }
      }
    }

  }
}

.history-row-container {
  background-color: $secondary-apli;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: transparent solid .5px;
  border-radius: 10px;
  padding: 1rem;
  margin: .5rem 1rem;
  width: 100%;

  .history-row-title {
    font-size: 18px;
    position: center;
  }
  .history-row-img {
    width: 65px;
    height: 90px;
    cursor: pointer;
    border-radius: 10px;
  }

  .history-row-content-2{
    display: flex;
    align-items: center;
    width: 100%;

    .history-row-content-desc {

      .history-row-subtitle {
        border: transparent solid 3px;
        border-radius: 8px;
      }
    }
    .history-row-content-desc-2 {
      flex: 1;

      .history-row-subtitle-2 {
        text-align: center;
        border: $secondary-apli solid 3px;
        border-radius: 8px;
        background-color: $primary-apli-transparent;
        position: center;
      }
    }

    .history-row-content-desc, .history-row-content-desc-2{
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
      padding: 0px;

      .history-row-subtitle, .history-row-subtitle-2{
        font-size: 13px;
        padding: 4px;
        color: $white;
      }
    }


  }


  .history-row-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

      .history-row-title {
        font-size: 18px;
      }
    }
  }


.bundle-row-container {
  background-color: $secondary-apli;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: transparent solid .5px;
  border-radius: 10px;
  padding: 1rem;
  margin: .5rem 1rem;
  width: 100%;

  .bundle-row-img {
    width: 65px;
    height: 90px;
    cursor: pointer;
    border-radius: 10px;
  }

  .bundle-row-content {
    display: flex;
    align-items: center;


    .bundle-row-content-desc {
      margin-left: 1rem;

      .bundle-row-title {

      }

      .bundle-row-subtitle {
        font-size: 12px;
        color: $white;
      }
    }

  }

  .bundle-row-switch {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 110px;

    .ant-switch {
      background-color: #ff0f20;
      height: 18px;

      &.ant-switch-checked {
        background-color: #59ff38;
      }
      &.ant-switch::after{
        height: 16px;
        width: 16px;
        border-radius:18px;
        top: 0;
      }
    }
    p {
      font-size: 12px;
      color: $white;

    }
  }

}
.panel-container-2{
  cursor: pointer;
  width: 60%;
}
.panel-container {
  border: transparent solid .5px;
  //border-radius: 10px;
  //min-height: 200px;
  margin: 1rem;
  width: 90%;

  .panel-header {
    background-color: $secondary-apli;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    height: 40px;

    &:hover {
      cursor: pointer;
    }

    .panel-header-title {

    }

    .panel-header-icon {
      font-size: 30px;
    }

  }

  .panel-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0rem;
    border-top: transparent solid .5px;
  }
}

.settings-container {
  padding: 0 .5rem;
  margin: 2rem auto;
  //border: $lightgray solid 1px;
  border-radius: 10px;
  max-width: 800px;
  position: relative;

  .settings-container-pop{
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 92%;
    z-index: 10;
    position: absolute;
  }
  &.support {
    padding: 0;
    margin: 0;
    max-width: unset;
    height: $inner-screen-size-with-footer;
    background:url('../img/b2.gif')  center center no-repeat;
    background-size: 80px 80px;

    iframe {
      height: 100%;
      width: 100%;
    }
  }





  &.userProfile{
    .nickname{
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      input{
        height: 50px;
        color: $white;
        border: 2px solid transparent;
        border-radius: 4px;
        background-color: $secondary-apli;
      }

      input:focus{
        outline: none;
      }
    }
    textarea {
      margin: 1rem 0;
    }
    .description-input{
      background-color: $secondary-apli;
      width: 100%;
      height: 160px;
      border-radius: 5px;
      border: 2px solid transparent;
      color: $white;
    }
    .description-input.focus{
      border: 2px solid $secondary-apli;
    }

    .drawer-avatar {
      margin: 1rem 0;
      position: relative;
      display: flex;
      justify-content: center;

      .drawer-avatar-overlay {
        z-index: 2;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        border-radius: 45px;
        //background-color: $overlay-color-light;
        font-size: 20px;
        color: $white;
        cursor: pointer;
        border: $white solid 0.5px;

        .icon-camera{
          width:1.5em;
          height:1.5em;
          position:absolute;
          background-color: transparent;
        }
      }

      input {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        cursor: pointer;
        position: absolute;
        opacity: 0;
      }

      img {
        width: 80px;
        height: 80px;
        border-radius: 40px;

      }
    }


    .userProfile-button {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      //border: $white solid 1px;
    }
  }




  &.feedback {
    .feedback-button {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      //border: $white solid 1px;
    }

    .feedback-icons {
      display: flex;
      align-items: center;
      justify-content: space-around;

      img {
        height: 120px;
        width: 120px;
        border-radius: 60px;
      }
    }

    textarea {
      margin: 1rem 0;
    }
    .feedback-form-control{
      background-color: $secondary-apli;
      width: 100%;
      height: 160px;
      border-radius: 5px;
      border: 2px solid transparent;
      color: $white;
    }
    .feedback-form-control.focus{
      border: 2px solid $secondary-apli;
    }
  }

  .settings-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 500px;
    margin: 2rem auto;
    .settings-dropdown.ant-select.ant-select-enabled .ant-select-selection{
      background-color: $secondary-apli;
      border: transparent;
      color: $white;

      .ant-select-arrow{
        color: $white;
      }
    }
    .ant-switch {
      background-color: #ff0f20;
      height: 18px;
      &.ant-switch-checked {
        background-color: #59ff38;
      }
      &.ant-switch::after{
        height: 16px;
        width: 16px;
        border-radius:18px;
        top: 0;
      }
    }

    .settings-left {
      margin-right: 1rem;

      .settings-title {
      }

      .settings-subtitle {
        font-size: 12px;
        color: $white;
      }
    }

    .settings-radio-item {
      display: flex;
      align-items: center;

      label {
        margin-left: 15px;
        margin-bottom: 0;
        margin-top: 2px;
      }
    }

    .settings-dropdown {
      width: 100%;
    }
  }

  .link-device {
    max-width: 500px;
    margin: 0 auto 1rem auto;

    p {
      color: $white;
    }

    .settings-device {
      display: flex;
      //justify-content: center;
      align-items: center;
      border: $secondary-apli solid 1px;
      cursor: pointer;
      //-webkit-box-shadow: $box-shadow-light;
      //box-shadow: $box-shadow-light;


      border-radius: 10px;
      padding: 1rem;
      margin: 1rem 0;

      img {
        height: 75px;
      }

      .settings-device-desc {
        width: 100%;
        margin-left: 2rem;
      }
    }
  }

  .deactivate-device {
    padding: 2rem;

    img {
      width: 100px;
    }

    button {
      width: 100%;
      height: 60px;
    }

    .deactivate-info {
      display: flex;
      align-items: center;
      margin: 2rem 1rem;

      .deactivate-info-title {
        margin-right: 1.5rem;
        font-size: 22px;
      }

      .deactivate-info-subtitle {
        color: $white;
        word-break: break-word;
      }
    }

    .form-control{
      background-color: transparent;
      border-color:#253d50;
      color:$white;

      &.rtl{
        direction: rtl;
      }
    }
    .deactivate-device-buttons {
      display: flex;
      justify-content: center;
      align-content: center;

      button {
        height: unset;

        &:first-child {
          margin-right: 1rem;
        }

        &:nth-child(2) {
          margin-left: 1rem;

        }
      }
    }
  }


  .notification-container {
    .notification-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 500px;
      margin: auto;

      h4{
        font-size: 1.2rem;
        margin-left: 10px;
      }
      .notification-header-icon {
        color: $white;
        font-size: 25px;
        margin-right: 10px;
      }
    }

    .notification-item-container {
      display: flex;
      position: relative;
      align-items: center;
      padding: 1rem;
      //-webkit-box-shadow: $box-shadow-light;
      //box-shadow: $box-shadow-light;
      border-radius: 10px;
      border: $secondary-apli solid 1px;
      max-width: 400px;
      margin: .8rem auto;
      cursor: pointer;
      text-decoration: none;
      z-index: 1;

      .item-card-x-notification{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 30px;
        height: 30px;
        font-size: 16px;
        color: $white;
        position: absolute;
        top: 4px;
        right: 8px;
        z-index: 2;
        &.rtl{
          right: 0;
          left: 8px;
        }
      }
      &.read-red{
        border-left: red solid 10px;
      }
      &.read-pink{
        border-left: deeppink solid 10px;
      }
      &.read-Yellow{
        border-left: $yellowColor solid 10px;
      }
      &.read {
        border-left: green solid 10px;
      }

      .add {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 10px;
        .notification-item-overlay {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          background-color: $overlay-color;
        }

        .notification-item-img {
          width: 90px;
          margin-right: 1rem;
          border-radius: 10px;
        }

        .notification-item-right {
          width: 100%;

          .notification-item-title {

          }

          .notification-item-desc {
            font-size: 12px;
            color: $lightgray;

          }

          .notification-item-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;

            .notification-item-time {

            }

            .notification-item-more {

            }

          }

        }
      }
    }
  }

}

.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;

  input {
    margin: 3rem 2rem 1rem 2rem;
    width: 70%;
    height: 60px;
    border-radius: 30px;
  }

  button {
    width: 140px;
    border-radius: 30px;
  }
}

.drawer-avatar-container {
  padding: .5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $primary-apli-transparent;
  border-bottom: solid 3px $secondary-apli;
  margin-bottom: 3px;

  .drawer-logo {
    width: 100px;
  }

  .profile-container{
    display: flex;
    padding: 0 1rem;
    width: 100%;


    .drawer-avatar {
      margin: 1rem 0;
      position: relative;

      .drawer-avatar-overlay {
        z-index: 2;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        border-radius: 45px;
        //background-color: $overlay-color-light;
        font-size: 20px;
        color: $white;
        cursor: pointer;
        border: $white solid 0.5px;

        .icon-camera{
          width:1.5em;
          height:1.5em;
          position:absolute;
          background-color: transparent;
          //margin-top: 4rem;
          //margin-left: 4rem;
        }
      }

      input {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        cursor: pointer;
        position: absolute;
        opacity: 0;
      }

      img {
        width: 80px;
        height: 80px;
        border-radius: 40px;

      }
    }

    .user-profile {
      display: flex;
      padding-left: .5rem;
      align-items: center;
      justify-content: space-between;
      width: 190px;

      .user-details{
        width: 100%;

        p{
          color: $white;
          font-size: 12px;
          margin-bottom: .25rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          span {
            text-decoration: underline;
          }
        }
        .followers{
          cursor: pointer;
        }
      }
      .edit-icon {
        svg{
          color: $white;
          width: 2rem;
          height: 1.5rem;
          cursor: pointer;
        }
      }
    }

  }


  .drawer-slogan {
    font-size: 12px;
    color: $white;
  }

  .drawer-info {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 1rem;
    &.rtl{
      flex-direction: row-reverse;
    }

    .drawer-info-title {
      color: $white;
    }

    .drawer-info-desc {
      color: $white;
    }
  }

  .media-info{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: transparent;
    border-bottom: solid 3px #253d50;

    img{
      width: 40px;
      height: 40px;
      margin: .5rem;
      cursor: pointer;
    }
  }

  .gift-dialog{
    display: flex;
    flex-direction: column;
    height: 85px;
    width: 100%;
    background-color: #505e76;
    margin-top:.5rem;

    p{
      font-size: 13px;
      font-family: "Montserrat";
      text-align: center;
      color: white;
      padding: 0.3rem 0.5rem 0rem 0.5rem;
    }

    .rate-element{
      padding-top: .5rem;
      width: 100%;
      height: 50%;
      align-items: center;
      display: flex;
      justify-content: center;

      .gift-icon{
        width: 40px;
        margin-left: 1rem;
        cursor: pointer;
        height: 40px;
      }
    }
  }
}

.splash-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $primary-apli;
  height: 100vh;
}

.movies-list-container {
  padding: 2rem;
}

.confirm-modal {
  width: 400px !important;

  .ant-modal-body {
    color: $white;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $primary-apli;
    -webkit-box-shadow: $box-shadow;
    box-shadow: $box-shadow;

    .sub-overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: $overlay-color;
      width: 100%;
      height: 100%;
      z-index: 2;
      top: 0;
      left: 0;
    }

    .confirm-modal-title, .confirm-modal-desc {
      color: $white;
      //text-align: center;
    }
    .confirm-modal-desc{
      font-size: 14px;
      &.rtl{
        text-align: right;
        direction: rtl;
      }
    }

    .confirm-modal-title {
      &.rtl{
        direction: rtl;
        text-align: right;
      }
      font-size: 20px;
      margin-bottom: 1rem;
    }

    .confirm-modal-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;

      button:first-child {
        margin-right: 1rem;
      }
    }

    .mobile-payment-dialog{
      padding-top: 1rem;

      .checkbox-payment{
        width: 20px;
        height: 20px;
        margin-right: .5rem;
      }
      .checkbox-field{
        display: flex;
      }
    }

  }
  .row-switch {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &.rtl{
      flex-direction: row-reverse;
    }
    .ant-switch {
      background-color: transparent;
      border-color: $secondary-apli;
      height: 18px;

      &.ant-switch-checked {
        background-color: $secondary-apli;
      }
      &.ant-switch::after{
        height: 16px;
        width: 16px;
        border-radius:18px;
        top: 0;
      }
    }
  }
}

.ant-modal-confirm .ant-modal-body{
  background-color: $primary-apli-transparent;

  .ant-modal-confirm-title{
    color: $white;
  }
  .anticon{
    color: $white;
  }
  .ant-modal-confirm-btns{
    .ant-btn{
      background-color: $secondary-apli;
      border: none;
      color: $white;
    }
  }
}

.img-modal {
  width: unset !important;
  .ant-modal-content {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: unset !important;

    .ant-modal-body {
      display: flex;
      //background-color: $primary-apli;
      background-color: transparent;
      flex-direction: column;
      align-items: center;

      .nickname{
        font-family: "Arial Rounded MT Bold";
        font-size: 15px;
      }
      .desc{
        font-size: 12px;
      }
      .followers{
        font-size: 10px;
      }

      p {
        color: $white;
        background-color: transparent;
        width: 100%;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
      }

      img {
        width: 240px;
        height: 240px;
        border-radius: 50%;
        border: 1px white solid;
      }

      .follow-icon{
        height: 40px;
        border: unset;
        border: unset;
        width: 90px;
        cursor: pointer;
        border-radius: unset;
      }

      .fetch-app-pop {
        background-color: $overlay-color-fetch;
        height: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 3;

        img {
          width: 80px;
          height: 80px;
          position: absolute;
          border-radius: unset;
          border:unset;
        }
      }
    }

  }

}

.sub-modal {
  .sub-number {
    font-size: 16px;
  }
  .auto-renew{
    font-size: 14px;
    font-family: "Arial Rounded MT Bold";
  }

  .ant-modal-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $primary-apli;
    color: $white;
    //border: $lightgray solid .5px;
    //border-radius: 10px;
    //overflow: hidden;
    -webkit-box-shadow: $box-shadow;
    box-shadow: $box-shadow;

    .sub-overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: $overlay-color;
      width: 100%;
      height: 100%;
      z-index: 2;
    }


    .sub-tabs {
      display: flex;

      .sub-tab {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 65px;
        font-size: 1.4rem;
        cursor: pointer;
        color: gray;
        background-color: transparent;
        border-bottom: $secondary-apli solid 3px;

        &.seletedfromPck{
          color: $white;
          border-bottom-color: $secondary-apli;
        }
        &:hover {
          //background-color: $primary-apli;
        }

        &.selected {
          background-color: $primary-apli;
          border-bottom-color: transparent;
          color: $white;
        }
      }
    }
    .sub-body {
      padding: 1rem;

      .sub-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        background-color: $secondary-apli;
        border-radius: 10px;
        padding: 0 2rem;
        margin: 1rem 0;
        &.rtl{
          .sub-item-left{
            text-align: right;
          }
          direction: rtl;
        }
        &:hover {
          cursor: pointer;
        }

        .sub-row-item {
          display: flex;
          align-items: center;
          min-width: 165px;
          margin: .5rem 0;

          .sub-icon {
            font-size: 20px;
            margin-right: 1rem;
            width: 35px;
          }
        }

        .sub-item-right {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          background-color: $primary-apli;
          border-radius: 10px;
          border-color: transparent;
          min-width: 80px;
          min-height: 75px;
          cursor: pointer;
        }
      }

      .sub-carousel-item {
        width: 70px;
        height: 100px;
        position: relative;

        .clickable-bundle {
          cursor: pointer;
        }

        .clickable {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          background-color: $secondary-apli;

          .sub-carousel-price {
            margin: .2rem 0;
          }

          img {
            width: 50px;
            //height: 40px;
          }

          &.selected {
            background-color: $red;
          }
        }

        img {
          width: 100%;
          height: 100%;
        }

        .sub-carousel-title {
          position: absolute;
          width: 100%;
          font-size: 12px;
          //height: 20px;
          background-color: rgba(0, 0, 0, .3);
          text-align: center;
          bottom: 0;
        }
      }

      .sub-you-get {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: .5rem 0;

        h5 {
          margin-top: 1rem;
          color: $white;
        }

        .sub-row {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 100%;
          margin-bottom: 1rem;

          .sub-row-item {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 165px;
            margin-top: 1rem;

            .sub-icon {
              font-size: 20px;
              margin-right: 1rem;
              width: 35px;
            }
          }
        }
      }

      .sub-buy {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        margin: .5rem 0;

        .sub-buy-button {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $secondary-apli;
          //border-radius: 5px;
          border-color: transparent;
          min-width: 80px;
          min-height: 45px;
          cursor: pointer;
        }
      }

      .movie-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 2rem;
        margin: 1rem 0;

        img {
          width: 100px;
        }

        .movie-row-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

        }
      }
    }

    .sub-gift-input {
      margin: 1rem 1rem 0 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }
}

.my-content-container {
  max-width: 800px;
  margin: auto;
  position: relative;

  .my-content-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.error-404-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;

  h1, h5 {
    color: $white;
    margin: 1rem 0;
    text-align: center;
  }

  h1 {
    font-size: 4rem;
  }

  img {
    width: 240px
  }

  input {
    margin: 3rem 2rem;
    width: 70%;
    height: 60px;
    border-radius: 30px;
  }

}

.collapse-group {
  .item, .sub-item {
    border-radius: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem;
  }

  .sub-item {
    justify-content: flex-start;

    .sub-item-icon {
      margin: 0 .6rem;
      width: 16px;
    }
  }
}

.player-slider {
  position: relative;
  width: 100%;

  //&:hover {
  //  .rail, .main-track {
  //    height: 5px;
  //  }
  //
  //  .handle {
  //    top: -5px;
  //    width: 15px;
  //    height: 15px;
  //    margin-left: -5;
  //  }
  //
  //}

  .rail {
    transition: all .2s ease;
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 5px;
    background-color: #8B9CB6;
    cursor: pointer;
    display: flex;
  }

  .main-track {
    transition: all .2s ease;
    position: absolute;
    height: 4px;
    border-radius: 5px;
    cursor: pointer;

    &.playing-track {
      z-index: 2;
      //background-color: $primary-apli;
      background-color: red;
    }

    &.buffer-track {
      z-index: 1;
      background-color: #4b586d;
    }
  }

  .handle {
    transition: all .2s ease;
    position: absolute;
    margin-left: -1px;
    z-index: 3;
    top: -3px;
    width: 10px;
    height: 10px;
    border: 0;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    background-color: red;
    color: $white;
  }
}

.general-error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: $inner-screen-size-with-footer;

}

.footer {
  //height: 20px;
  padding: 0 2rem;
  //margin: 1rem 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .copyrights {
    //color: $primary-apli;
    color: white;
    font-size: 10px;
    text-align: center;
    margin-right: 3px;
  }

  .footer-divider {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .menu-link {
    margin: 0 10px;
    color: $lightgray;

    &:hover {
      text-decoration: none;
    }
  }
}

.sub-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: $overlay-color;
  width: 100%;
  height: 100%;
  z-index: 2;
  color: darkgray;
}


.live-report-modal{
  .ant-modal-content{
    background-color: $primary-apli;
  }

  h5{
    text-align: center;
    color:white;
  }
  p{
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    color: white;
  }
  .report-desc{
    color: darkgray;
  }
  .report-list{
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;

    button{
      margin: .4rem 0;
      background-color: transparent;
      color: white;
      border: unset;
      font-size: 14px;
      font-family: 'Montserrat', sans-serif;
    }
  }

}


.viewers-list-model{
  .ant-modal-content{
    h5{
      text-align: center;
      color: white;
      font-family: "Arial Rounded MT Bold";
    }
  }
  .ant-modal-body {
    background-color: $primary-apli;

    .viewers-list-item{
      display: flex;
      margin: 1rem 0rem;
      flex-direction: row;
      justify-content: space-between;

      p{
        color: white;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
      }

      img{
        margin-right: 10px;
        width: 40px;
        height: 40px;
        border-radius: 20px;
      }

      .follow-icon{
        width: unset;
        border-radius: unset;
        cursor: pointer;
      }

      .user-info{
        display: flex;
      }
      .user-info-items{
        display: flex;
        flex-direction: column;
      }
    }
  }
}


.session-end-model{
  .ant-modal-body{
    background-image: url('../img/icons/live_event_end.png');
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: 100% 100%;
    justify-content: center;
    min-height: 400px;

    .profile-icon{
      height: 90px;
      width: 90px;
      border: 3px white solid;
      border-radius: 45px;
    }
    .follow-icon{
      height: 45px;
      width: 90px;
      cursor: pointer;
    }
    p{
      color: white;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 15px;
      margin: .5rem;
    }
  }
}

.insert-reason-report-modal{
  .ant-modal-content{
    background-color: $primary-apli;
  }
  .ant-modal-body{
    display: flex;
    flex-direction: column;

    textarea{
      width: 100%;
      padding: 1rem;
      border-radius: 10px;
    }

    button{
      border: unset;
      height: 50px;
      color: white;
      font-size: 16px;
      margin-top: 1rem;
      font-family: 'Montserrat', sans-serif;
      background-color: $secondary-apli;
      border-radius: 8px;
      text-transform: uppercase;
    }
  }
}

.notification-modal {
  .ant-modal-content{
    background-color: $primary-apli-transparent;
  }
  .notifiaction-image{
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    img{
      width: 90px;
      height: 140px;
      border-radius: 10px;

      &.iv{
        width: 90px;
        height: 90px;
      }
    }
  }

  .ant-modal-body {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .notification-modal-title {
      color: $white;
      font-size: 18px;
      margin-bottom: 10px;
    }
    .notification-modal-message-referral-rtl{
      direction: rtl;
    }
    .notification-modal-message, .notification-modal-message-referral{
      color: $white;
      font-size: 12px;
      margin-bottom: 20px;
    }
    .notification-modal-referral-code{
      margin-bottom: 10px;
      font-size: 16px;
      font-family: "Arial Rounded MT Bold";
      text-align: center;
      color: $white;
    }
    .notification-modal-message-referral{
      &.rtl{
        direction: rtl;
      }
    }
    .notification-modal-message-referral{
      text-align: center;
      padding: 0px 30px;
    }
    .notification-modal-buttons {
      display: flex;
      justify-content: flex-start;
      .notification-btn{
        background-color: $secondary-apli;
        width: 100%;
        color: $white;
        height: 40px;
        font-size: 16px;
        border-color: transparent;
        border-radius: 5px;
      }
    }
  }
}

.continueWatching-modal{
  .ant-modal-content{
    background-color: $primary-apli-transparent;
  }
  .continueWatching-image{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    img{
      margin-right: 1rem;
      width: 90px;
      height: 140px;
      border-radius: 10px;
    }
    p{
      font-size: 15px;
      font-family: "Arial Rounded MT Bold";
      color: $white;
    }
  }

  .cancel{
    background-color: $secondary-apli;
    width: 100%;
    color: $white;
    height: 40px;
    font-family: "Arial Rounded MT Bold";
    font-size: 15px;
    border-color: transparent;
    border-radius: 12px;
    position: relative;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .bar {
    margin-bottom: 1rem;
    background-color: $secondary-apli;
    width: 100%;
    color: $white;
    height: 40px;
    //border:1px solid $secondary-apli;
    border-radius: 12px;
    position: relative;
    width: 100%;
    cursor: pointer;


    .myBar {
      width: 0%;
      height: 100%;
      background-color: $yellowColor;
      position: absolute;
      border-radius: 12px;
    }
    //.in {
    //  animation: fill 5s linear 1;
    //  height: 100%;
    //  border-radius: 12px;
    //  position: absolute;
    //  width: 100%;
    //  background-color: #ffcc00;
    //}
    .time-left{
      z-index: 2;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      span{
        margin-left: .5rem;
        text-align: center;
        font-family: "Arial Rounded MT Bold";
        font-size: 15px;
      }
    }
  }

  @keyframes fill {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }

  @-webkit-keyframes live {
    0% {
      border-color: red;
    }
    50% {
      border-color: transparent;
    }
  }

  @keyframes live {
    0% {
      border-color: red;
    }
    50% {
      border-color: transparent;
    }
  }

  @keyframes fillPredictionAnimation {
    0% {
      width: 0%;
    }
    100% {
      width: 220%;
    }
  }

  .ant-modal-body {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .continueWatching-modal-title {
      color: $white;
      font-size: 17px;
      font-family: "Arial Rounded MT Bold";
      margin-bottom: 10px;
    }
    .continueWatching-modal-message-referral-rtl{
      direction: rtl;
    }
    .continueWatching-modal-message, .continueWatching-modal-message-referral{
      color: $white;
      font-size: 12px;
      margin-bottom: 20px;
    }
    .continueWatching-modal-referral-code{
      margin-bottom: 10px;
      font-size: 16px;
      font-family: "Arial Rounded MT Bold";
      text-align: center;
      color: $white;
    }
    .continueWatching-modal-message-referral{
      &.rtl{
        direction: rtl;
      }
    }
    .continueWatching-modal-message-referral{
      text-align: center;
      padding: 0px 30px;
    }
    .continueWatching-modal-buttons {
      display: flex;
      justify-content: flex-start;
      .continueWatching-btn{
        background-color: $secondary-apli;
        width: 100%;
        color: $white;
        height: 40px;
        font-size: 16px;
        border-color: transparent;
        border-radius: 5px;
      }
    }
  }
}

.limit-number {
  font-size: 14px;
}
.demo-player{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .content{
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: flex;
    h1,h2{
      color: $white;
    }
  }
  .player-demo{
    height: 100vh;
  }
}

.followers-following-list{
  display: flex;
  max-width: 600px;
  flex-direction: column;
  margin:auto;
  align-items: center;
  justify-content: center;

  .tabs{
    width: 100%;
    margin: 1rem 0rem;
    button{
      width: 50%;
      color: white;
      font-size: 16px;
      font-family: "Arial Rounded MT Bold";
      height: 60px;
      border: unset;
      background-color: transparent;
      text-transform: uppercase;

      &.active{
        border-bottom: 2px solid $yellowColor;
      }
    }
  }
  .list-items{
    display: flex;
    flex-wrap: wrap;

    .user-info{
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: .5rem;
      p{
        color: white;
        font-size: 10px;
        font-family: 'Montserrat', sans-serif;
      }
      .profile{
        width: 60px;
        height: 60px;
        border-radius: 30px;
        border: 2px solid;
      }
      .name{
        font-size: 13px;
        font-family: "Arial Rounded MT Bold";
      }
      .follow{
        cursor: pointer;
        width: 75px;
      }
    }
  }
}

.webview-container{
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;

  iframe{
    padding-bottom: 60px;
    height: 100%;
    border: unset;
    background-color: white;
  }

  .button-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    height: 60px;
    position: fixed;
    bottom: 0;

    button{
      width: 49%;
      background-color: $primary-apli;
      border: unset;
      color: white;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      cursor: pointer;
    }
  }
}

.error-modal {
  .ant-modal-content{
    background-color: $primary-apli-transparent;
  }
  .ant-modal-body{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .error-modal-title {
      color: $white;
      font-size: 18px;
      font-family: 'Montserrat', sans-serif;
      margin-bottom: 10px;
    }
    .error-modal-message{
      color: $white;
      font-size: 14px;
      font-weight: 100;
      margin-bottom: 20px;
    }
    .error-modal-buttons {
      display: flex;
      justify-content: flex-start;
      .error-btn{
        background-color: $secondary-apli;
        width: 100%;
        color: $white;
        height: 40px;
        font-size: 16px;
        border-color: transparent;
        border-radius: 5px;
      }
    }
  }
}

@import "media-queries";
