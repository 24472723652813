$light-font-color: #212529;
$light-background-color: #f8f9fa;

$dark-font-color: #fff;
$dark-background-color: #343a40;

$box-shadow: 0px 0px 10px 0px rgba(233, 233, 233, 0);
$box-shadow-light: 0px 0px 2px 0px rgba(233, 233, 233, 0);

$fade-down: linear-gradient(to top, rgba(243, 243, 243, 0), rgba(000, 000, 000, 1));
$fade-up: linear-gradient(to bottom, rgba(243, 243, 243, 0), rgba(000, 000, 000, 1));
$yellowColor:#dbb20c;

$chat-mine-color:#f2bb06;
$chat-rec-color:#0f5d82;


$lightgray: lightgray;
$darkorange: darkorange;
$red: red;


$white: white;
$gray: gray;

$primary-apli: #031929;//29467f

$primary-apli-transparent: #031929ee;//29467f

$secondary-apli: #253d50;//f79320

$overlay-color: rgba(0, 0, 0, .7);
$overlay-color-light: rgba(0, 0, 0, .4);
$overlay-color-fetch: rgba(0, 0, 0, .25);

$inner-screen-size : calc(100vh - 60px);
$inner-screen-size-with-footer : calc(100vh - 110px);

$grid-breakpoints-xs: 0;
$grid-breakpoints-sm: 576px;
$grid-breakpoints-md: 768px;
$demo-video-shift:862px;
$grid-breakpoints-lg: 992px;
$grid-breakpoints-xl: 1200px;

