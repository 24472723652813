@media only screen and(max-width: $grid-breakpoints-lg) {
  .movie-header {
    .movie-header-left {
      justify-content: center;

      &.rtl {
        justify-content: center;
      }

      .movie-poster {
        width: 200px;
        height: 300px;
      }
    }
  }

  .login-container {
    .login-container-left {
      text-align: center;

      h1 {
        text-align: center;
      }
    }
  }
}
@media only screen and(max-width: $demo-video-shift) {
  .demo-player{
    .content{
      display: none;
    }
  }
  .login-container {
    background-position: 90% 50%;
  }
}
@media only screen and(min-width: $grid-breakpoints-lg) {
  .freeBundle-text,.freeMsg-text{
    max-width: 320px;
  }
}
@media only screen and(max-width: $grid-breakpoints-sm) {
  .panel-container-2{
    width: 90%;
  }

}

@media only screen and (max-width: $grid-breakpoints-md) {

  body, html { // disable text selection when on mobile
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .movie-card-container {
    width: 109px;

    .movie-card-continue-img{
      top: 32%;
      right: 32%;
    }
    .movie-card-continue-img{
      width: 40px;
      height: 40px;
      top: 62px;
      right: 35px;
    }
    .movie-card-overlay {
      height: 164px;
    }

    .movie-card-img {
      width: 109px;
      height: 164px;
    }

    .movie-card-footer {
      padding: 0 4px;

      .movie-card-star {
        font-size: 16px;
      }
    }
  }

  .bundle-card-container {
    //width: 125px;


    //.bundle-card-overlay {
    //  height: 146px;
    //}

    //.bundle-card-img {
    //  width: 125px;
    //  height: 146px;
    //}

    .bundle-card-footer {
      padding:4px;

      .bundle-card-star {
        font-size: 16px;
      }
    }
  }

  .channel-card-container {
    width: 146px;

    .channel-card-overlay {
      height: 146px;
    }

    .channel-card-img {
      width: 146px;
      height: 146px;
    }

    .channel-card-footer {

      .channel-card-star {
        font-size: 16px;
      }
    }
  }

  .series-card-container {
    width: 146px;

    .series-card-continue-img{
      top: 28%;
      //right: 40px;
    }

    .series-card-overlay {
      height: 117px;
    }

    .series-card-img {
      width: 146px;
      height: 117px;
    }

    .series-card-footer {

      .series-card-star {
        font-size: 16px;
      }
    }
  }

  .item-card-container {
    //width: 146px;

    .item-card-overlay {
      //height: 146px;
    }

    .item-card-footer {
      height: 28px;
      padding: 0 4px;

      .item-card-star {
        font-size: 16px;
      }
    }
  }

  .history-card-container {
    width: 146px;

    .history-card-img {
      width: 146px;
      height: 146px;
    }
  }

  .movie-header {
    .movie-header-left {
      justify-content: center;

      &.rtl {
        justify-content: center;
      }

      .movie-poster {
        width: 200px;
        height: 300px;
      }
    }

    .movie-header-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 1rem;
    }
  }

  .movie-footer {
    .row {
      justify-content: center;
    }
  }

  .listing-container {
    padding: .6rem;
  }

  .login-container {

    .card-signin {
      max-width: 90%;
      margin: auto;
    }

    .login-container-left {
      padding: 0
    }
  }

  .item-details-container {
    .item-header {
      .item-header-meta {
        justify-content: center;
      }

      .item-header-left {
        justify-content: center;
      }

      .item-header-right {
        margin-top: 1rem;
        text-align: center;

        .item-header-actions {
          justify-content: center;
          width: 100%;

          .seasons-dropdown {
            width: 80%;
          }
        }

      }

    }

    .item-footer {
      .row {
        justify-content: center;
      }
    }

    .action-button {
      margin: 0 2px;
    }
  }

  .hero-img {
    height: 35vh;
    object-fit: cover;

  }

  .popup-container {
    display: none;
  }

  .carousel-container {
    //margin: .2rem;
  }

  .player-container {
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;

    &.chat-enabled {
      padding: 1rem;

      .player {
        flex: 4;
        margin: 0 1rem;
      }

      .chat {
        //flex: 7;
        flex:4;
        margin: 1rem;
        height: 36%;
      }
    }

    .player {
      width: 100%;
      height: 100%; //unset

      &.chat-enabled{
        height: 60%;
      }

      &.paused {
        .player-controls {
          .player-center {
            .player-action-button {
              font-size: 25px;
            }
          }

          .player-action-button {
            font-size: 16px;
          }

          .player-live-text {
            font-size: 13px;
          }

          .player-popup {
            bottom: 10px;
            right: 0;
            max-height: calc(100%);
            transform: scale(.8);
          }

          .player-playlist {
            position: fixed;
            bottom: 0;
            left: 0;
            top: unset;
            width: 100%;
            height: 250px;
          }


        }
      }

    }

    .chat {
      width: 100%;
      height: inherit;
    }

    &.minimized {
      height: 140px;

      .player {
        height: 100%;
      }

      &.chat-enabled {
        padding: 0;
      }

      bottom: 5px;
      left: 2%;
      right: 0;
      width: 96%;
    }
  }

}

@media only screen and (max-height: 700px) {
  .chat {
    //display: none !important;
  }
}
@media only screen and (max-width: 995px) {
  .menu-container {
    .menu-left {
      flex: unset;

      .menu-links {
        display: none;
      }
    }

    .menu-right {
      display: none;
    }
  }
}
